import { Injectable } from '@angular/core';
var wx = require('weixin-js-sdk');
enum CodeState {
  start, processing
}
@Injectable({
  providedIn: 'root'
})
export class ContextService {
  public httpCount: number = 0;
  public userNumber: string;
  public isShowCover: boolean = false;
  public restHost: string = "http://wxpay.ikacang.cn:8180"; // API请求头 
  public urlStr: string = "";
  public state: string = "123456" // Client 端的状态值。用于第三方应用防止 CSRF 攻击，成功 授权后回调时会原样带回。请务必严格按照流程检查用户 与 state 参数状态的绑定。
  public code: string = ""; // 临时code本地存储
  public CodeState = CodeState;
  public getCodeState: CodeState = CodeState.start;
  public openId: string = "";
  public nowTime: number;
  public developMode: boolean = true;

  constructor() {
    console.log(wx);
  }


  /**返回上一页 */
  goBack() {
    window.history.go(-1);
  }
  public log(msg: string) {
    if (!this.developMode) return;
    // console.log(msg);
  }

  public incHttpCount(uri: string, params?: any) {
    this.httpCount++;
    if (this.developMode) {
      this.log(
        this.restHost + uri + " start, count=" + this.httpCount + " params=" + JSON.stringify(params)
      );
    }
  }

  imgUrl(url: string) {
    return "http://wxpay.ikacang.cn/image/" + url;
  }

  public decHttpCount(uri: string, params?: any) {
    this.httpCount--;
    if (this.developMode) {
      this.log(
        this.restHost + uri + " close, count=" + this.httpCount + " params=" + JSON.stringify(params)
      );
    }
  }
  public getTemporaryToken() {
    let self = this;
    self.getCodeState = CodeState.processing;
    self.urlStr = "https://www.abchina.com/luascript/oauthLogin/{\"client_id\":\"0cb9f738-e242-45ce-bc55-a0bac3149c5f\",\"redirect_uri\":\"https://abc.ikacang.cn/product\",\"state\":\"123456\",\"scope\":\"openid\",\"response_type\":\"code\"}";
    window.location.href = self.urlStr;
  }
}
