import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ContextService } from "./context.service";
import { Observable, Subject } from "rxjs";

export interface HttpLocal {
  enabled: boolean;
  fileName: string;
}

export interface HttpData {
  name: string;
  data: any;
}

@Injectable()
export class HttpService {
  public dataSource: Subject<HttpData>;
  constructor(
    protected ctx: ContextService,
    protected http: HttpClient
  ) {
    this.dataSource = new Subject<HttpData>();
  }

  protected dataChange(name: string, data: any) {
    this.dataSource.next(<HttpData>{ name: name, data: data });
  }

  public async postAsync(uri: string, params?: any) {
    this.ctx.httpCount += 1;
    let oHttp: Observable<any> = this.http.post<any>(
      this.ctx.restHost + uri,
      params,
      this.httpOptions()
    );
    return await oHttp.toPromise();
  }

  public getLocal(uri: string) {
    return this.http.get(uri);
  }

  private isFromLocal(local?: HttpLocal) {
    return this.ctx.developMode && local && local.enabled;
  }

  public get(
    uri: string,
    params?: any,
    header?: HttpHeaders,
    local?: HttpLocal,
    onCallback?: (resp) => void
  ): Observable<any> {
    if (this.isFromLocal(local)) {
      return this.getLocal(local.fileName);
    } else {
      return this.doPost(uri, params ? params : {}, header, onCallback);
    }
  }

  public post(
    uri: string,
    params?: any,
    header?: HttpHeaders,
    local?: HttpLocal,
    onCallback?: (resp) => void,
  ): Observable<any> {
    // if (this.isFromLocal(local)) {
    //   return this.getLocal(local.fileName);
    // } else {
    return this.doPost(uri, params ? params : {}, header, onCallback);
    // }
  }

  public httpOptions(): any {
    let headers = new Headers();
    return { headers: headers };
  }

  public doPost(
    uri: string,
    params: any,
    headers?: HttpHeaders,
    onCallback?: (resp) => void,
  ): Observable<any> {
    let header: HttpHeaders = headers ? headers : new HttpHeaders();
    let oHttp: Observable<any> = this.http.post<any>(
      this.ctx.restHost + uri,
      params,
      { headers: header }
    );
    // this.ctx.incHttpCount(uri, params);
    let self = this;
    return new Observable(observer => {
      oHttp.subscribe(
        resp => {
          // self.ctx.decHttpCount(uri, params);
          // if (Array.isArray(resp) || resp.isSuccess) {
          if (onCallback) onCallback(resp);
          observer.next(resp);
          observer.complete();
          // } else {
          //   let error: string = resp.message
          //     ? resp.message
          //     : '未知错误';
          //   console.log(error);
          //   observer.error(error);
          // }
        },
        error => {
          switch (error.status) {
            case 503:
              console.log('503');
              break;
            case 404:
              console.log('404');
              break;
            default:
              break;
          }
          self.ctx.decHttpCount(uri, params);
          console.log(uri + "," + error.message);
          observer.error(error);
        }
      );
    });
  }
}
