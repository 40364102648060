import { ContextService } from './context.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PayhttpService extends HttpService {
  constructor(protected http: HttpClient, protected ctx: ContextService) { super(ctx, http); }

  
  public getProdList(param: any) {
    return this.post("/unionmall/IKaCangProductService", param);
  }

  public payMent(param: any) {
    // return this.post("/unionmall/OrderService", param);
    return this.post("/unionmall/IKaCangOrderService", param);
  }

  public payMent2(param: any) {
    // return this.post("/unionmall/OrderService", param);
    return this.post("/unionmall/IKaCangOrderService", param);
  }

  public getToken(param: any) {
    return this.post("/unionmall/LoginService", param);
  }

  public getToken2(param: any) {
    return this.post("/unionmall/LoginService", param);
  }
  
}

