import { PayhttpService } from './../services/payhttp.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ContextService } from '../services/context.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'kaishu';
  public code: string = "";
  public state: string = "";
  constructor(private ctx: ContextService, public route: ActivatedRoute, private http: PayhttpService,private httpclient: HttpClient,) { }
  ngOnInit() {
    // this.httpclient.get("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx820c2507051bc1fa&redirect_uri=http://wxpay.ikacang.cn/prodlist&response_type=code&scope=snsapi_base&state=123#wechat_redirect").subscribe((res: any) => {
    //   console.log(res);
    // })
  }
}