import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

//配置路由对象
const routes: Routes = [
  {
    path: "",
    component: AppComponent,
    children: [
      {
        path: "login",
        data: {},
        loadChildren: () => import("../page/login/login.module").then(m => m.LoginModule)
      },
      {
        path: "prodlist",
        data: {},
        loadChildren: () => import("../page/prodlist/prodlist.module").then(m => m.ProdlistModule)
      },
      {
        path: "payment",
        data: {},
        loadChildren: () => import("../page/payment/payment.module").then(m => m.PaymentModule)
      },
      {
        path: "payresult",
        data: {},
        loadChildren: () => import("../page/payresult/payresult.module").then(m => m.PayresultModule)
      },
      {
        path: "orderlist",
        data: {},
        loadChildren: () => import("../page/orderlist/orderlist.module").then(m => m.OrderlistModule)
      },
      {
        path: "orderdetails",
        data: {},
        loadChildren: () => import("../page/orderdetails/orderdetails.module").then(m => m.OrderdetailsModule)
      },
      {
        path: '',
        redirectTo: 'prodlist',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
